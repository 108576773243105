.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

body {background:#eee;}
.badge {margin-right: 0.4rem;}   
article a {text-decoration:none;}  
a h1 {color:#222;}
.navigation {margin-bottom:3rem;}
.navbar {padding-right:0.5rem; padding-left:0.5rem;}
.navbar-expand-lg .navbar-nav .nav-link {padding-right:0.5rem; padding-left:0.5rem;}
.selected {font-weight:bold; color: #f00;}
.error {background: #fff; border-radius: 0.4rem; text-align: center; padding: 3rem 0;}
footer {padding: 3rem 0;}
.rowEpisode img {width:100%;}

